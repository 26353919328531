import Button from 'components/button';
import EmptyState from 'components/empty-state';
import LinkButton from 'components/link-button';

const Error = () => {
  return (
    <EmptyState
      icon="empty-state-box"
      title={
        <p className="mb-6 text-xl font-bold text-text-3">
          {'Oups, quelque chose s’est mal passé.'}
        </p>
      }
      actions={
        <div className="flex flex-col items-center justify-center space-y-3 text-center">
          <LinkButton href="/">
            <Button>{'Retour à l’accueil'}</Button>
          </LinkButton>
        </div>
      }
      className="md:mt-42 mt-36 lg:mt-60"
    />
  );
};

export default Error;
