import Link, { LinkProps } from 'next/link';
import { forwardRef, Ref } from 'react';

interface LinkButtonProps {
  href: LinkProps['href'];
  children: JSX.Element;
  className?: string;
  shouldOpenInNewTab?: boolean;
}

const LinkButton = forwardRef(
  (
    { href, children, className, shouldOpenInNewTab }: LinkButtonProps,
    forwardedRef: Ref<HTMLAnchorElement>,
  ): React.ReactNode => {
    if (!href) {
      return children;
    }

    return (
      <Link
        ref={forwardedRef}
        href={href}
        className={className}
        {...(shouldOpenInNewTab && {
          rel: 'noopener noreferrer',
          target: '_blank',
        })}
      >
        {children}
      </Link>
    );
  },
);

LinkButton.displayName = 'LinkButton';

export default LinkButton;
