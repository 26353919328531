import cx from 'clsx';
import { Icons } from 'v2.api/src/common-generic';

import Icon from './icon';

interface Props {
  icon?: Icons;
  title?: React.ReactNode;
  sentence?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
}

const EmptyState = ({
  icon,
  title,
  sentence,
  actions,
  className,
}: Props): React.ReactNode => {
  return (
    <div
      className={cx(
        'flex flex-col items-center justify-center text-center',
        className,
      )}
    >
      <div className="mb-6">
        <Icon type={icon} size="custom" className="h-24 w-32" />
      </div>

      {title}
      {sentence}
      {actions}
    </div>
  );
};

export default EmptyState;
